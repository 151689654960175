<template lang="pug">
div(class='grid grid-cols-1 gap-y-4 gap-x-6' :class='columns')
  div(v-for='video in videos' :key='video.id')
    template(v-if='video.type == "YOUTUBE"')
      template(v-if='!$device.value.isMobile')
        VideoYouTubeThumb(:video='video' @click='setVideo(video)')
        VideoYouTubeOverlay(:video='video' :section='section')
      template(v-else)
        VideoYouTubePlayer(:video='video')
        div(v-if='video.title' class='mt-2 text-sm mb-2')
          div(class='text-gray-darkest font-bold')
            InlineHtml(:text='video.title')
          div(v-if='video.duration' class='text-gray-dark')
            | {{ durationToClock(video.duration) }}

    VideoWistiaThumb(v-if='video.type == "WISTIA"' :video='video')
</template>
<script setup lang="ts">
const { $overlay } = useNuxtApp()
const { videos, section, gridCols = 4 } = defineProps<{ videos: Video[]; section: string; gridCols?: number }>()

const columns = computed(() => `grid-cols-${gridCols}`)

function setVideo(video: Video) {
  $overlay.open(`${section}-${video.id}`)
}
</script>
