<template lang="pug">
div(v-if='renderDealerLocator')
  BaseButton(:to='dealerRoute' size='MEDIUM' class='w-full' @click='emitDealerLinkEvent') Find A Local Dealer
</template>

<script setup lang="ts">
const { $sitewideConfig, $uiEvents } = useNuxtApp()
const { showDealerLocatorOnProductPage } = useDealerLocator()
const { data } = defineProps<{
  data: Product
}>()

const dealerRoute = computed(() => {
  let path = '/dealer-locator/'

  if ($sitewideConfig.config.dealerLocatorShowBrandFacet && data.brand.name) path += `?brand=${data.brand.name}`

  return path
})

const renderDealerLocator = computed(() => {
  return showDealerLocatorOnProductPage(data.brand?.name)
})

function emitDealerLinkEvent(event: any) {
  $uiEvents.$emit('dealerLocatorProductLinkClicked', {
    product: data,
    linkText: event.target?.innerText,
    clickUrl: event.target?.pathname,
  })
}
</script>
