<template lang="pug">
div(class='grid grid-cols-1 gap-6 lg:grid-cols-3 md:grid-cols-2')
  div(v-for='(imageBullet, index) in data' :key='imageBullet.image.key')
    img(
      v-if='imageBullet.image.key'
      :key='imageBullet.image.key'
      width='715'
      height='536'
      :src='getImageUrl(imageBullet.image.key, imageBullet.image.filename, 715, 536)'
      :alt='imageBullet.image.title || ""'
      class='border cursor-pointer'
      loading='lazy'
      @click='loadLightbox(index)'
    )
    div(class='content pt-6')
      div(v-if='imageBullet.title' class='font-bold uppercase pb-2')
        InlineHtml(:text='imageBullet.title')
      div(v-if='imageBullet.content' class='text-sm')
        InlineHtml(:text='imageBullet.content')
</template>
<script setup lang="ts">
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const { data } = defineProps<{
  data: ImageBullet[]
}>()

function loadLightbox(index: number) {
  const items: PhotoswipeItem[] = []
  data.forEach((bullet) => {
    if (!bullet.image.key) return
    items.push({
      src: getImageUrl(bullet.image.key, bullet.image.filename, 1024, 768) || '',
      width: 1024,
      height: 768,
      caption: bullet.title,
    })
  })
  showLightbox(items, index)
}
</script>
