<template lang="pug">
div
  div(v-if='$device.isMobile')
    button(
      class='w-full p-3 font-bold bg-gray-lighter cursor-pointer flex items-center justify-between'
      @click='isOpen = !isOpen'
    )
      span {{ header }}
      span {{ isOpen ? '&ndash;' : '+' }}
    transition(
      enter-active-class='transition-opacity ease-out duration-200'
      enter-from-class='opacity-0'
      leave-active-class='transition-opacity ease-in duration-200'
      leave-to-class='opacity-0'
    )
      div(v-show='isOpen')
        slot

  div(v-else)
    slot
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
const isOpen: Ref<boolean> = ref(false)

const { header } = defineProps<{
  header: string
}>()
</script>
