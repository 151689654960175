<template lang="pug">
div(
  :data-product-id='yotpoId'
  :data-name='yotpoName'
  :data-url='dataURL'
  class='yotpo yotpo-main-widget !w-full [&_.write-question-review-buttons-container_.write-question-review-button]:h-auto'
)
</template>

<script setup lang="ts">
const { $sitewideConfig } = useNuxtApp()
const { params } = useRoute()
const { yotpoId, yotpoName } = defineProps<{
  yotpoId: string
  yotpoName: string
}>()
useYotpo()
const dataURL = computed(() => {
  return 'https://' + $sitewideConfig.domain + '/p/' + params.productLine
})
</script>
